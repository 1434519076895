import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../../components/seo'
import SecondaryHeading from '../../components/SecondaryHeading'
import SectionDoubleColumn from '../../components/SectionDoubleColumn'
import SideCol from '../../components/SectionDoubleColumn/SideCol'
import ReactMarkdown from 'react-markdown'
import Image from '../../components/image'
import Fade from 'react-reveal/Fade'
import generateLink from '../../utils/generateLink'

const Vision = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          pagesData {
            company {
              subPage {
                vision {
                  title {
                    en
                    de
                    it
                  }
                  firstSection {
                    en
                    de
                    it
                  }
                  
                  secondSection {
                    subtitle{
                      en
                      de
                      it
                    }
                    points{
                      en
                      de
                      it
                    }
                  }
                }
              }
              sideSection {
                firstBlock {
                  de
                  en
                  it
                }
                links {
                  text {
                    en
                    it
                    de
                  }
                  path
                }
              }
            }
          }
        }
      }
    }
      `
  )

  const {
    sideSection,
    subPage: {
      vision
    }
  } = site.siteMetadata.pagesData.company

  const mainCol = (
    <div>
      <Fade right>
        <div>
          {/* <h2 className='text-3xl underline font-bold'>{vision.title[langKey]}</h2> */}
          <div className='mb-10'>
            <ReactMarkdown source={vision.firstSection[langKey]} />
          </div>
          <Image imgRef='infographics' />
        </div>
        <div className='mt-16'>
          <h3 className='text-2xl underline font-bold'>{vision.secondSection.subtitle[langKey]}</h3>
          <ul className='my-10'>
            {
              vision.secondSection.points.map((el, i) => <li key={`value_point_${i}`}><ReactMarkdown source={el[langKey]} /></li>)
            }
          </ul>
        </div>
      </Fade>
    </div>
  )

  const linksForSide = sideSection.links.map((el) => ({ path: generateLink(langKey, '/company/' + el.path), text: el.text[langKey], selected: el.path === 'vision' }))
  const sideCol = <SideCol links={linksForSide} textBlock={sideSection.firstBlock[langKey]} />

  return (
    <>
      <Seo title={vision.title[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={vision.title[langKey]} />
      <SectionDoubleColumn mainCol={mainCol} sideCol={sideCol} mainVisibleInSmallScreen />
    </>
  )
}

export default Vision
